#headPage {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #ffffff;
}
#headPage .headCenter {
  width: 1168px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
#headPage .headCenter .headLeft {
  display: flex;
  align-items: center;
}
#headPage .headCenter .headLeft span {
  font-weight: 500;
  color: #333;
}
#headPage img {
  cursor: pointer;
}
#headPage .headRight {
  display: flex;
}
#headPage .headRight .homeIndex {
  padding-left: 30px;
  color: #2878ff;
}
#headPage .headRight .homeIndex:after {
  content: "";
  width: 20px;
  height: 1px;
  border-bottom: 3px solid #2878ff;
  display: block;
  margin: 0 auto;
}
#headPage .headRight .homeIndex_1 {
  padding-left: 30px;
}
#headPage .headRight .homeIndex_2 {
  color: #2878ff;
  padding-left: 30px;
}
#headPage .headRight .homeIndex_2:after {
  content: "";
  width: 60px;
  height: 1px;
  border-bottom: 3px solid #2878ff;
  display: block;
  margin: 0 auto;
}
#headPage .text {
  font-size: 17px;
  cursor: pointer;
}
