 /deep/ .el-carousel__indicators--horizontal {
  width: 1440px;
  margin: 0 auto;
  bottom: -26px;
}
@media (max-width: 1500px) {
   /deep/ .el-carousel__indicators--horizontal {
    width: 1200px;
  }
}
 /deep/ .el-carousel__button {
  background-color: #fff;
}
 /deep/ .el-carousel__indicator.is-active button {
  background-color: #2878FF;
}
 /deep/ .el-carousel__indicator {
  position: relative;
  top: -175px;
}
.bannerH {
  height: 500px;
}
.bannerH /deep/ .el-carousel__container {
  height: 100% !important;
}
.bannerH /deep/ .el-carousel__container /deep/ .el-carousel__item {
  height: 100%;
}
.bannerHInner {
  width: 100%;
  height: 500px;
}
@media (max-width: 1500px) {
  .bannerH {
    height: 415px;
  }
  .bannerHInner {
    height: 415px;
  }
}
