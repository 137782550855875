#aboutUs {
  height: 100vh;
  overflow-y: scroll;
  background-color: #f8fafd;
}
#aboutUs .container {
  padding: 60px 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#aboutUs .container .title {
  color: #3f4652;
  font-size: 34px;
  text-align: center;
}
#aboutUs .container .text-1 {
  width: 1168px;
  text-align: center;
  font-size: 17px;
  margin-top: 16px;
  color: #5c6b84;
}
#aboutUs .container .img {
  margin-top: 36px;
}
#aboutUs .container .text-2 {
  width: 1168px;
  margin-top: 20px;
  text-indent: 30px;
  color: #5c6b84;
  font-size: 17px;
}
#aboutUs .container-2 {
  width: 1168px;
  margin: 0 auto;
}
#aboutUs .container-2 .title {
  margin-top: 80px;
  color: #3f4652;
  font-size: 34px;
  text-align: center;
}
#aboutUs .container-2 .flex {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
#aboutUs .container-2 .flex .container-2-item {
  width: 180px;
  height: 140px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#aboutUs .container-2 .flex .container-2-item .text-1 {
  font-size: 40px;
  color: #2878ff;
  font-weight: 600;
}
#aboutUs .container-2 .flex .container-2-item .text-2 {
  font-size: 20px;
  color: #2878ff;
}
#aboutUs .container-2 .flex .container-2-item .text-3 {
  font-size: 20px;
  color: #3f4652;
  margin-top: 20px;
}
#aboutUs .container-2 .flex .container-2-item:hover {
  box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.16);
}
#aboutUs .container-2 .text-4 {
  font-size: 17px;
  color: #5c6b84;
  margin-top: 20px;
  text-align: center;
}
#aboutUs .container-3 {
  width: 100%;
  height: 390px;
  padding: 60px 0;
  box-sizing: border-box;
  margin-top: 60px;
  background-image: url("../../../assets/loginIndex/about_us_2.png");
  background-size: 100% 100%;
}
#aboutUs .container-3 .title {
  color: #ffffff;
  font-size: 34px;
  text-align: center;
}
#aboutUs .container-3 .cent {
  width: 1168px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
#aboutUs .container-3 .cent .item {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
#aboutUs .container-3 .cent .item img {
  width: 122px;
  height: 122px;
}
#aboutUs .container-3 .cent .item .t-1 {
  font-size: 20px;
  margin-top: 20px;
}
#aboutUs .container-3 .cent .item .t-2 {
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
}
#aboutUs .container-4 {
  width: 1168px;
  margin: 0 auto;
  padding: 60px 0;
}
#aboutUs .container-4 .title {
  font-size: 34px;
  color: #3f4652;
  text-align: center;
}
#aboutUs .container-4 .cont-1 {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
#aboutUs .container-4 .cont-1 .item {
  width: 221px;
  height: 105px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
#aboutUs .container-4 .cont-1 .item:hover {
  box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.16);
}
#aboutUs .container-4 .cont-2 {
  margin-top: 16px;
  display: flex;
}
#aboutUs .container-4 .cont-2 .item {
  width: 221px;
  height: 105px;
  background-color: #ffffff;
  box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px 16px 0;
}
