







































































































































#headPage {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #ffffff;
  .headCenter {
    width: 1168px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .headLeft {
      display: flex;
      align-items: center;
      span {
        font-weight: 500;
        color: #333;
      }
    }
  }
  img {
    cursor: pointer;
  }
  .headRight {
    display: flex;
    .homeIndex {
      padding-left: 30px;
      color: #2878ff;
      &:after {
        content: "";
        width: 20px;
        height: 1px;
        border-bottom: 3px solid #2878ff;
        display: block;
        margin: 0 auto;
      }
    }
    .homeIndex_1 {
      padding-left: 30px;
    }
    .homeIndex_2 {
      color: #2878ff;
      padding-left:  30px;
      &:after {
        content: "";
        width: 60px;
        height: 1px;
        border-bottom: 3px solid #2878ff;
        display: block;
        margin: 0 auto;
      }
    }
  }
  .text {
    font-size: 17px;
    cursor: pointer;
  }
}
